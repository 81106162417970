import barba from '@barba/core';
import { gsap } from "gsap/all";
import Cookies from 'js-cookie';

import Header from "./header";
import NavMobile from "./nav-mobile";
import Reveal from "./reveal";
import Carousel from './carousel';
import Reviews from './reviews';
import HealthQuiz from './health-quiz';

export default class BarbaJs {

  constructor() {
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    const mask = document.querySelector('.barba-mask');
    const langSwitcher = document.querySelector('.language-switcher');
    // const navigation = document.querySelector('.navigation--header');
    const navigationLinks = document.querySelectorAll('.navigation--header-link');
    const navigationScroll = document.querySelector('.header--navigation-wrapper .overflow-auto');

    const getAnalyticsContent = function () {
      let analytics_consent = false;
      const cookie_status = Cookies.get('cookie_status');
      if (cookie_status) {
        const categories = cookie_status.split(',');
        analytics_consent = categories.includes('analytics');
      }
      return analytics_consent;
    }

    // Define dataLayer and the gtag function.
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }

    // Set default consent values
    gtag('consent', 'default', {
      'ad_storage': getAnalyticsContent() ? 'granted' : 'denied',
      'ad_user_data': getAnalyticsContent() ? 'granted' : 'denied',
      'ad_personalization': getAnalyticsContent() ? 'granted' : 'denied',
      'analytics_storage': getAnalyticsContent() ? 'granted' : 'denied',
    });

    // listen for cookie updates
    body.addEventListener('cookies:saved', (event) => {
      gtag('consent', 'update', {
        'ad_storage': event.detail.includes('analytics') ? 'granted' : 'denied',
        'ad_user_data': event.detail.includes('analytics') ? 'granted' : 'denied',
        'ad_personalization': event.detail.includes('analytics') ? 'granted' : 'denied',
        'analytics_storage': event.detail.includes('analytics') ? 'granted' : 'denied',
      });

      // history pushState to trigger GTM Tag
      const url = new URL(window.location);
      history.pushState({}, "", url);
    });


    barba.init({
      timeout: 5000,

      // debug: true,
      // cacheIgnore: true,
      // prefetchIgnore: true,

      // requestError: (trigger, action, url, response) => {
      //   console.log({
      //     trigger: trigger,
      //     action: action,
      //     url: url,
      //     response: response,
      //   })
      // },

      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba') || el.classList.contains('active')),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            const glossaryDivider = document.querySelector('.glossary-divider');
            let glossaryDividerHeight = glossaryDivider ? glossaryDivider.clientHeight + 'px' : 0;
            html.style.setProperty('--glossary-divider-height', glossaryDividerHeight);

            // scroll to anchor
            if (window.location.hash) {
              const target = document.querySelector(window.location.hash);
              if (target) {
                setTimeout(() => {
                  target.scrollIntoView({
                    behavior: 'smooth',
                  });
                }, 250);
              }
            }

            const buttonAppointmentHero = document.querySelector('.button-appointment-hero');
            const footer = document.querySelector('#footer');
            if (buttonAppointmentHero && footer) {
              buttonAppointmentHero.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                setTimeout(() => {
                  footer.scrollIntoView({
                    behavior: "smooth",
                  });
                }, 1);
              });
            }

            setTimeout(() => {
              new Header();
              new NavMobile();
              new Reveal('.js--reveal');
              new Carousel('.js--carousel');
              new Reviews('.js--reviews');
              new HealthQuiz('.js--quiz');
            }, 1);

            return gsap.to(body, {
              opacity: 1,
              duration: 1,
              delay: 0.25,
            });
          },

          beforeLeave(data) {
            mask.classList.replace('opacity-0', 'opacity-100');
            mask.classList.replace('invisible', 'visible');

            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 0.5,
              // delay: data.trigger.classList != undefined && data.trigger.classList.contains('navigation--header-link') ? 1 : 0.4,
              delay: data.trigger.classList != undefined && data.trigger.classList.contains('navigation--header-link') ? 1 : 0.4,
            });
          },

          afterLeave(data) {
            // update body classes
            body.classList.toggle('is-home', data.next.namespace === 'home');
            body.classList.toggle('not-home', data.next.namespace !== 'home');
            data.current.container.remove();
          },

          beforeEnter(data) {
            if (langSwitcher != null) {
              langSwitcher.href = data.next.container.dataset.translationUrl;
            }

            navigationLinks.forEach(navigationLink => {
              navigationLink.classList.toggle('text-theme-red', navigationLink.dataset.slug === data.next.namespace || navigationLink.dataset.slug === data.next.container.dataset.slug);
            });

            // Set default consent values
            gtag('consent', 'default', {
              'ad_storage': getAnalyticsContent() ? 'granted' : 'denied',
              'ad_user_data': getAnalyticsContent() ? 'granted' : 'denied',
              'ad_personalization': getAnalyticsContent() ? 'granted' : 'denied',
              'analytics_storage': getAnalyticsContent() ? 'granted' : 'denied',
            });

            data.next.container.style.opacity = 0;
          },


          enter(data) {
            // reinit JS
            setTimeout(() => {
              // new Header();
              new NavMobile();
              new Reveal('.js--reveal');
              new Carousel('.js--carousel');
              new Reviews('.js--reviews');
              new HealthQuiz('.js--quiz');
            }, 1);

          },

          afterEnter(data) {
            window.scrollTo(0, 0);
            navigationScroll.scrollTo(0, 0);

            const glossaryDivider = document.querySelector('.glossary-divider');
            let glossaryDividerHeight = glossaryDivider ? glossaryDivider.clientHeight + 'px' : 0;
            html.style.setProperty('--glossary-divider-height', glossaryDividerHeight);


            const buttonAppointmentHero = document.querySelector('.button-appointment-hero');
            const footer = document.querySelector('#footer');
            if (buttonAppointmentHero && footer) {
              buttonAppointmentHero.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                setTimeout(() => {
                  footer.scrollIntoView({
                    behavior: "smooth",
                  });
                }, 1);
              });
            }


            return gsap.to(data.next.container, {
              opacity: 1,
              duration: 1,
              delay: 0.5,
              // onStart: () => {
              // },
              onComplete: () => {
                mask.classList.replace('opacity-100', 'opacity-0');
                mask.classList.replace('visible', 'invisible');

                // scroll to anchor
                if (window.location.hash) {
                  const target = document.querySelector(window.location.hash);
                  if (target) {
                    setTimeout(() => {
                      target.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }, 250);
                  }
                }
              }
            });
          }

        },
      ],

    });
  }

}
